

body {
  background-color: #97cded;
  font-size: 0.875em;
  height: 100%;
}



.App {
  display: flex;
  flex-flow: row wrap;
  background-color: #97cded;
  text-align: center;
  justify-content: center;

  height: 100%;
}

.App > * {
  flex: 1 100%;
}

.contacts {
  padding-top: 1.5em;
}

.contacts a {
  padding-left: 0.3em;
}

.AppLogo {
  position: relative;
  top: 0;
  left: 0;
  color: transparent;  
  height: 11em; 
}


.App-logo-boat {
  background-image: url(assets/boat.svg);
  background-repeat: no-repeat;
  background-size: 1.5em;
  background-position: center;
  margin-left: auto;
  margin-right: auto;

  position: relative;
  left: -17.5em;
  top: -10.8em;

  width: 2em;
  height: 3em;
}

.App-logo-full {
  background-image: url(assets/logo_wedding.svg);
  background-repeat: no-repeat;
  background-size: 50em;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  position: relative;
  left: 0;
  top: 0;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10em;
}

@media (max-width: 750px) {
  .App-logo-boat {
    background-image: url(assets/boat.svg);
    background-repeat: no-repeat;
    background-size: 1.5m;
    background-position: center;
    margin-left: auto;
    margin-right: auto;

    position: relative;
    left: -7em;
    top: -15.8em;

    width: 2em; /*or your image's width*/
    height: 3em; /*or your image's height*/    
    /*border: solid red;*/
  }

  .App-logo-full {
    background-image: url(assets/logo_wedding_mobile.svg);
    background-repeat: no-repeat;
    background-size: 24em;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    
    position: relative;
    left: 0em;
    top: 0;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    height: 14em;
    /*border: solid red;*/
  }

  .AppLogo {
    height: 14em;    
  }

  
}

.App-header {
  padding-top: 10em;
  position: relative;
  left: 0;
  top: 0;
  /*border: solid yellow;*/
}

.App-footer {
  align-self: flex-end;
  width: 200px;
}
